import React from "react";
import { graphql } from "gatsby";
import Single from "./Single";

function SinglePage({ data, pageContext }) {
  return <Single data={data} pageContext={pageContext} type="page" />;
}

export default SinglePage;

export const query = graphql`
  query($slug: String!) {
    article: nodePage(fields: { slug: { eq: $slug } }) {
      drupal_id
      title
      field_disable_main_header
      field_quicklinks_title
      field_quicklinks_description
      field_description_formatted {
        processed
      }
      field_accordion_title
      field_display_contact_form
      fields {
        slug
      }
      body {
        processed
        summary
      }
      relationships {
        field_header_background_image {
          drupal_id
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
        field_banner_image {
          drupal_id
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
        field_quicklinks_files {
          name
          drupal_id
          field_from_url {
            uri
          }
          relationships {
            field_media_document {
              uri {
                url
              }
            }
          }
        }
        field_accordion_nodes {
          drupal_id
          title
          field_accordion_tab_content {
            processed
          }
          relationships {
            field_accordion_tab_files {
              drupal_id
              name
              relationships {
                field_media_document {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
        field_page_content_blocks {
          ... on node__call_to_action {
            drupal_id
            field_call_to_action_background
            field_call_to_action_heading_typ
            title
            field_call_to_action_subhead
            field_call_to_action_content
            field_call_to_action_link {
              title
              uri
            }
            relationships {
              field_call_to_action_logo {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
                field_media_image {
                  alt
                }
              }
              field_header_background_image {
                drupal_id
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
            internal {
              type
            }
          }
          ... on node__section_header {
            drupal_id
            title
            internal {
              type
            }
            relationships {
              field_section_header_banner_img {
                relationships {
                  field_media_image {
                    uri {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on node__content_row {
            drupal_id
            title
            internal {
              type
            }
            field_content_row_content {
              processed
            }
            field_content_row_more_link {
              title
              uri
            }
            field_content_row_files_title
            field_content_row_files_desc
            field_description_formatted {
              processed
            }
            field_content_row_display_recent
            relationships {
              field_content_row_files {
                name
                drupal_id
                relationships {
                  field_media_document {
                    uri {
                      url
                    }
                  }
                }
              }
              field_content_row_video {
                drupal_id
                field_media_oembed_video
                name
              }
              field_personnel_list {
                drupal_id
                title
                field_job_title
                field_email
                field_phone
                body {
                  summary
                  processed
                }
                field_linkedin_profile {
                  uri
                }
                field_twitter_profile {
                  uri
                }
                relationships {
                  field_team_category {
                    drupal_id
                    name
                  }
                  field_photo {
                    relationships {
                      field_media_image {
                        uri {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on node__highlight {
            drupal_id
            title
            internal {
              type
            }
            field_highlight_content {
              processed
            }
            field_highlight_link {
              title
              uri
            }
          }
        }
      }
    }
    news: allNodeArticle(
      filter: { promote: { eq: true } }
      sort: { order: DESC, fields: field_date }
    ) {
      edges {
        node {
          drupal_id
          title
          field_date(formatString: "MMMM Do, YYYY")
          fields {
            slug
          }
          body {
            processed
            summary
          }
          relationships {
            field_tags {
              drupal_id
              name
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        drupalUrl
        adminEmail
        title
      }
    }
  }
`;
